import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import { BusinessCreationDataUpdate, ExistingBusinessProfileData } from "../../models/models";
import { getBusinessProfileInfo, getRegisteredBusiness } from "../../api/firebase";

interface BusinessContextType{
    currentBusiness: BusinessCreationDataUpdate | null;
    businessProfile: ExistingBusinessProfileData | null;
    setCurrentBusiness: (store_id: string) => Promise<void>;
}

const BusinessContext = createContext<BusinessContextType>({
    currentBusiness: null,
    businessProfile: null,
    setCurrentBusiness: async (store_id) => {}
});

export const useBusinessContext = () => useContext(BusinessContext);

interface BusinessContextProviderProps{
    children?: ReactNode
}

export default function BusinessContextProvider({children}: BusinessContextProviderProps){
    const [business, setCurrentBusiness] = useState<BusinessCreationDataUpdate | null>(null);
    const [profile, setProfile] = useState<ExistingBusinessProfileData | null>(null);

    useEffect(() => {
        if (business){
            (async () => {
                setProfile( await getBusinessProfileInfo(business.store_id) );
            })();
        }
    }, [business]);

    const onSetCurrentBusiness = useCallback(async (store_id: string) => {
        setCurrentBusiness( await getRegisteredBusiness(store_id) );
    }, []);

    const value = {
        currentBusiness: business, 
        businessProfile: profile,
        setCurrentBusiness: onSetCurrentBusiness
    }

    return (
        <BusinessContext.Provider value={value}>
            {children}
        </BusinessContext.Provider>
    )
}