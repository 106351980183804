import { FieldValue, Timestamp } from "firebase/firestore";

export interface UserData{
    auth: any;
    extraDetails: any;
}

export interface BusinessCreationRequest {
    business_type: string;
    tin_number: string;
    phone_number: string;
    store_name: string;
    business_email: string;
    business_sector: string;
    registered_country: string;
    operating_location: string;
    creator_national_id: string;
    additional_comment: string;
}

export interface BusinessCreationData extends BusinessCreationRequest  {
    created_uid: string;
    submitted_at: Timestamp;
}

export interface BusinessCreationDataUpdate {
    _reference?: string;
    business_type: string;
    tin_number: string;
    phone_number: string;
    store_name: string;
    shareholders: string;
    business_sector: string;
    operating_location: string;
    created_uid: string;
    store_id: string;
    creator_national_id : string;
    additional_comment: string;
}

export enum ValidFieldsBusinessCreationDataUpdate {
    BusinessType        = "business_type",
    BusinessSector      = "business_sector",
    BusinessName        = "store_name",
    BusinessEmail       = "business_email",
    CreatedUid          = "created_uid",
    DatabaseReference   = "_reference", 
    OperatingLocation   = "operating_location",
    PhoneNumber         = "phone_number",
    RegisteredCountry   = "registered_country",
    ShareHolders        = "shareholders",
    StoreId             = "store_id",
    TinNumber           = "tin_number",
    CreatorNationalId   = "creator_national_id",
    AdditionalComment   = "additional_comment",
}

export interface PostMediaContent{
    type: string;
    source: string;
}

export interface PostContent{
    text: string;
    media?: PostMediaContent[];
}

export interface PostData{
    store_id: string;
    content: PostContent;
    posted: Timestamp;
    id: string;
}

export interface UpdatingPostData extends PostData{
    _reference?: string;
}

export interface UploadingPostData{
    store_id: string;
    content: PostContent;
    posted: FieldValue;
    id: string;
}

export interface BusinessToUserMap{
    store_id: string;
    uid: string;
    role: string;
    created_uid: string;
}

interface ProductPrice {
    amount: number;
    currency: string;
}

export interface ProductData {
    id: string;
    product_name: string;
    price: ProductPrice;
    store_id: string;
    media?: PostMediaContent[];
    categories?: string[];
    description?: string;
    type: string;
}

export interface ExistingProductData extends ProductData{
    _reference?: string;
}

export interface BusinessProfileData{
    image: string;
    name: string;
    small_description: string;
    store_id: string;
    about?: string;
}

export interface ExistingBusinessProfileData extends BusinessProfileData{
    _reference?: string;
    url_basename: string;
}

export const ProductDataDefault : ProductData = {
    id: "",
    product_name : "",
    price: {
        currency: "RWF", 
        amount: 0
    }, 
    store_id: "",
    type: "product"
}