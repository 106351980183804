import { Card, Icon, Segment, Table } from 'semantic-ui-react';

function CurrentPlanDisplay() {
    return (
        <Segment basic fluid>
            <h2
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                Current Plan
            </h2>
            <Card fluid>
                <Card.Content>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {/* Tariff */}
                        <div style={{ textAlign: "center" }}>
                            <Icon name="briefcase" size="large" />
                            <div>
                                <strong>Tariff</strong>
                            </div>
                            <div>Basic Plan</div>
                        </div>

                        {/* Tariff Cost */}
                        <div style={{ textAlign: "center" }}>
                            <Icon name="dollar sign" size="large" />
                            <div>
                                <strong>Tariff Cost</strong>
                            </div>
                            <div>RWF 12,000 / year</div>
                        </div>

                        {/* Renewal Date */}
                        <div style={{ textAlign: "center" }}>
                            <Icon name="calendar alternate outline" size="large" />
                            <div>
                                <strong>Renewal Date</strong>
                            </div>
                            <div>Jan 1, 2025</div>
                        </div>

                        {/* Buttons 
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <Button basic color="blue" icon labelPosition="left">
                            <Icon name="envelope" />
                            Contact us
                        </Button>
                        <Button color="blue" icon labelPosition="left">
                            <Icon name="edit" />
                            Change plan
                        </Button>
                    </div> */}
                    </div>
                </Card.Content>
            </Card>
        </Segment>
    );
};


function SubscriptionsHistory() {
    const data = [
        {
            date: "01/01/2025",
            details: "Basic Plan, Yearly",
            amount: "RWF 12,000",
            invoice: "-",
        },
    ];

    return (
        <Segment basic fluid>
            <h2
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                Subscriptions History
            </h2>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Details</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Download</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.map((item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{item.date}</Table.Cell>
                            <Table.Cell>{item.details}</Table.Cell>
                            <Table.Cell>{item.amount}</Table.Cell>
                            <Table.Cell>
                                <a href="#">{item.invoice}</a>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );
};

export default function BillingSubPage() {
    return (
        <Segment
            fluid
            basic
        >
            <CurrentPlanDisplay />
            <SubscriptionsHistory />
        </Segment>
    );
}