import { createMedia } from '@artsy/fresnel';
import {
    Container, Menu, Segment, Sidebar, Icon, Grid, Header
} from 'semantic-ui-react';
import { DefaultHeader } from '../components/DefaultHeader';
import AffiliatedBusinessesCardGroup from '../components/AffiliatedBusinessesCardGroup';
import { ReactNode, useState } from 'react';
import { InView } from 'react-intersection-observer';

interface ResponsiveContainerProps {
    children: ReactNode
}

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
});

function DeskopContainer({ children }: ResponsiveContainerProps) {
    const [inView, setInView] = useState(false);

    return (
        <Media greaterThan='mobile'>
            <InView onChange={() => setInView(!inView)}>
                <Segment
                    inverted
                    textAlign='center'
                    style={{ padding: '0.5em 0em' }}
                    vertical
                >
                    <DefaultHeader />
                </Segment>
            </InView>
            {children}
        </Media>
    )
}

function MobileContainer({ children }: { children: ReactNode }) {
    const [sideBarOpened, setSideBarOpened] = useState(false);
    return (
        <Media at='mobile'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={() => setSideBarOpened(false)}
                    vertical
                    visible={sideBarOpened}
                >
                    <DefaultHeader mobile />
                </Sidebar>
                <Sidebar.Pusher dimmed={sideBarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ padding: '0.5em 0em' }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item onClick={() => setSideBarOpened(true)}>
                                    <Icon name='sidebar' />
                                </Menu.Item>
                                <Menu.Item as='a' header href="/" position='right'>
                                    Chaguoo - Business Panel
                                </Menu.Item>
                            </Menu>
                        </Container>
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    );
}

function ResponsiveContainer({ children }: ResponsiveContainerProps) {
    return (
        <MediaContextProvider>
            <DeskopContainer>{children}</DeskopContainer>
            <MobileContainer>{children}</MobileContainer>
        </MediaContextProvider>
    );
}

export default function HomePage() {
    return (
        <ResponsiveContainer>
            <Segment style={{ padding: '2em 0em' }} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column style={{ justifyContent: 'left', display: 'flex' }}>
                            <p style={{ fontSize: '1.33em' }}>Welcome to the business panel. Here you will be able to manage how the businesses on the Chaguoo Platforms.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment basic vertical style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Header as='h3' style={{ fontSize: '2em' }}>
                    Affiliated Businesses
                </Header>
                <AffiliatedBusinessesCardGroup />
            </Segment>
        </ResponsiveContainer>
    );
}