import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBusinessContext } from '../../storage/context/BusinessContext';
import { Container, Icon, Menu, Segment, Sidebar, Button } from 'semantic-ui-react';
import PostContextProvider from '../../storage/context/PostsContext';
import ProductContextProvider from '../../storage/context/ProductContext';
import BillingSubPage from './SubscriptionsPage';
import PostsSubPage from './PostsSubPage';
import ProductsSubPage from './ProductsSubPage';
import AboutSubPage from './AboutSubPage';
import AboutContextProvider from '../../storage/context/AboutContext';
import { createMedia } from '@artsy/fresnel';
import { ResponsiveContainer } from '../../components/DefaultResponsiveContainer';

const { MediaContextProvider } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
});

export default function BusinessPage() {
    const { businessid } = useParams();
    const { setCurrentBusiness } = useBusinessContext();
    const [activeItem, setActiveItem] = useState('Posts');
    const [sidebarVisible, setSidebarVisible] = useState(false);

    useEffect(() => {
        if (businessid) {
            setCurrentBusiness(businessid);
        }
    }, [businessid, setCurrentBusiness]);

    const renderActiveComponent = () => {
        switch (activeItem) {
            case 'Posts':
                return <PostsSubPage />;
            case 'Products / Services':
                return <ProductsSubPage />;
            case 'Subscriptions':
                return <BillingSubPage />;
            case 'Business Profile':
                return <AboutSubPage />;
            default:
                return <PostsSubPage />;
        }
    };

    const menuItems = [
        'Posts',
        'Products / Services',
        'Subscriptions',
        'Business Profile'
    ];

    return (
        <PostContextProvider>
            <ProductContextProvider>
                <AboutContextProvider>
                    <ResponsiveContainer>
                        <MediaContextProvider>
                            <Sidebar.Pushable
                                style={{
                                    height: '100vh',
                                    margin: 0,
                                    overflow: 'hidden'
                                }}
                            >
                                <Sidebar
                                    as={Menu}
                                    animation='overlay'
                                    icon='labeled'
                                    inverted
                                    vertical
                                    visible={sidebarVisible}
                                    width='thin'
                                    style={{
                                        height: '100%',
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        margin: 0,
                                        paddingTop: '60px', // Space for potential header
                                        overflowY: 'auto'
                                    }}
                                >

                                    <Menu.Item
                                        style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: '20px'
                                        }}
                                    >
                                        <Button
                                            icon
                                            onClick={() => setSidebarVisible(false)}
                                            style={{
                                                zIndex: 1000,
                                                color: "white",
                                                backgroundColor: 'transparent'
                                            }}
                                        >
                                            <Icon name='close' />
                                        </Button>
                                    </Menu.Item>
                                    {menuItems.map((item) => (
                                        <Menu.Item
                                            key={item}
                                            name={item}
                                            active={activeItem === item}
                                            onClick={() => {
                                                setActiveItem(item);
                                            }}
                                        >
                                            {item}
                                        </Menu.Item>
                                    ))}
                                </Sidebar>
                                <Sidebar.Pusher
                                    style={{
                                        height: '100%',
                                        overflowY: 'auto',
                                    }}
                                >
                                    <Segment
                                        basic
                                        style={{
                                            height: '100%',
                                            padding: '20px'
                                        }}
                                    >
                                        <Button
                                            icon
                                            onClick={() => setSidebarVisible(!sidebarVisible)}
                                            style={{
                                                position: 'fixed',
                                                top: '10px',
                                                left: '10px',
                                                zIndex: 1000,
                                                color: "white"
                                            }}
                                        >
                                            <Icon name='sidebar' />
                                        </Button>

                                        <Container fluid>
                                            <Segment basic>
                                                {renderActiveComponent()}
                                            </Segment>
                                        </Container>
                                    </Segment>
                                </Sidebar.Pusher>
                            </Sidebar.Pushable>
                        </MediaContextProvider>
                    </ResponsiveContainer>
                </AboutContextProvider>
            </ProductContextProvider>
        </PostContextProvider>
    )
}